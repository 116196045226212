import React from 'react';
import { AiFillHome, AiOutlineFileSearch } from 'react-icons/ai';
import { MdAttachEmail, MdSecurity, MdWifiCalling3 } from 'react-icons/md';
import { FaClipboardList, FaMoneyCheckAlt } from 'react-icons/fa';
import { GoFileMedia } from 'react-icons/go';
import groups from './userGroups';
import { GiEgyptianBird, GiPineTree } from 'react-icons/gi';
import { VscNotebookTemplate } from 'react-icons/vsc';

const navData = [
  {
    title: 'Home',
    path: '/',
    componentName: 'Home',
    icon: <AiFillHome />,
    accessGroup: groups.DEFAULT,
  },
  // {
  //   title: "Campaigns",
  //   path: "/campaigns",
  //   componentName: "Campaigns",
  //   icon: <FaClipboardList />,
  //   accessGroup: groups.ADMIN,
  // },
  // {
  //   title: "Email Segment Creator",
  //   path: "/email",
  //   componentName: "Email",
  //   icon: <MdAttachEmail />,
  //   accessGroup: groups.BACK_OFFICE,
  // },
  {
    title: 'Segment Creator',
    path: '/segment',
    componentName: 'SegmentCreator',
    icon: <VscNotebookTemplate />,
    accessGroup: groups.BACK_OFFICE,
  },
  {
    title: 'Model Controls',
    path: '/model-controls',
    componentName: 'ModelControls',
    icon: <MdWifiCalling3 />,
    accessGroup: groups.BACK_OFFICE,
  },
  {
    title: 'Media',
    path: '/media',
    componentName: 'Media',
    icon: <GoFileMedia />,
    accessGroup: groups.DEFAULT,
  },
  // {
  //   title: "Sales File Processor",
  //   path: "/salesFilesPro",
  //   componentName: "SalesFilesPro",
  //   icon: <AiOutlineFileSearch />,
  //   accessGroup: groups.BACK_OFFICE,
  // },
  {
    title: 'Woodpecker',
    path: '/woodpecker',
    componentName: 'Woodpecker',
    icon: <GiEgyptianBird />,
    accessGroup: groups.DEFAULT,
  },
  {
    title: 'Juniper',
    path: '/juniper',
    componentName: 'Juniper',
    icon: <GiPineTree />,
    accessGroup: groups.ADMIN,
  },
  {
    title: 'Access Control',
    path: '/access-control',
    componentName: 'AccessControl',
    icon: <MdSecurity />,
    accessGroup: groups.MANAGER,
  },
  {
    title: 'Internal Collections Manager',
    path: '/meta',
    componentName: 'Meta',
    icon: <FaMoneyCheckAlt />,
    accessGroup: groups.ADMIN || groups.MANAGER,
  },
  {
    title: 'Login',
    path: '/login',
    componentName: 'Login',
    hiddenFromNav: true,
    public: true,
  },
];

export default navData;

export function userCanAccessPage(page, userGroups) {
  return page.public || userGroups.includes(groups.ADMIN) || userGroups.includes(page.accessGroup);
}

export function userIsManager(userGroups) {
  return userGroups.includes(groups.MANAGER) || userGroups.includes(groups.ADMIN);
}

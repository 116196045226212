const userGroups = {
  DEFAULT: 'us-east-2_K51oJVKOu_AzureAD',
  AGENT: 'agent',
  BACK_OFFICE: 'back_office',
  LEGAL_COMPLIANCE: 'legal_compliance',
  MANAGER: 'manager',
  ADMIN: 'admin',
};

export default userGroups;

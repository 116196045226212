import React, { useContext, useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableDataCell,
  TableHead,
  TableHeadCell,
  TableRow,
  Window,
  WindowContent,
  WindowHeader,
} from "react95";
import styled from "styled-components";
import { JordanContext } from "./JordanContext";

const Wrapper = styled.div`
  padding: 5rem;
  background: ${({ theme }) => theme.desktopBackground};
`;

function JorTable() {
  const { campaignData } = useContext(JordanContext);

  return (
    <div style={{ height: "40%" }}>
      <Window style={{ width: "100%", height: "100%" }}>
        <WindowHeader>Genesis.exe</WindowHeader>
        <WindowContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeadCell>Type</TableHeadCell>
                <TableHeadCell>Name</TableHeadCell>
                <TableHeadCell disabled>Level</TableHeadCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {campaignData?.slice(0, 5).map((item) => {
                return (
                  <TableRow>
                    <TableDataCell style={{ textAlign: "center" }}>
                      <span role="img" aria-label="LEAF">
                        🌿
                      </span>
                    </TableDataCell>
                    <TableDataCell>{item.template_name}</TableDataCell>
                    <TableDataCell>
                      {Math.ceil(Math.random() * 100)}
                    </TableDataCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </WindowContent>
      </Window>
    </div>
  );
}

export default JorTable;

import React from "react";
import { Frame, styleReset } from "react95";
import { createGlobalStyle, ThemeProvider } from "styled-components";

import map from "../JordanMode/Images/map.png";
import car from "../JordanMode/Images/car.png";
import iconsleft from "../JordanMode/Images/iconsleft.png";

import {
  carStyle,
  mapStyle,
  innerFrame,
  rightSide,
  leftSide,
  iconsLeftStyle,
  iconsLeft,
} from "../../../../../styles/jordan.module.css";
/* Pick a theme of your choice */
import ash from "react95/dist/themes/ash";
import styled from "styled-components";

/* Original Windows95 font (optional) */
import ms_sans_serif from "react95/dist/fonts/ms_sans_serif.woff2";
import ms_sans_serif_bold from "react95/dist/fonts/ms_sans_serif_bold.woff2";
import JorTable from "./JorTable";
import { Default } from "./JorButton";
import useScreenSize from "../../../../Windowsize";

const GlobalStyles = createGlobalStyle`
  ${styleReset}
  @font-face {
    font-family: 'ms_sans_serif';
    src: url('${ms_sans_serif}') format('woff2');
    font-weight: 400;
    font-style: normal
  }
  @font-face {
    font-family: 'ms_sans_serif';
    src: url('${ms_sans_serif_bold}') format('woff2');
    font-weight: bold;
    font-style: normal
  }
  body, input, select, textarea {
    font-family: 'ms_sans_serif';
  }
`;
const quotes = [
  "Welcome to Jurassic Park",
  "Life finds a way",
  "If The Pirates of the Caribbean breaks down, the pirates don’t eat the tourists.",
  "Your scientists were so preoccupied with whether or not they could, they didn’t stop to think if they should.",
  "Some of the worst things imaginable have been done with the best intentions.",
  "Hold onto your butts.",
  "You didn’t ask for reality; you asked for more teeth!",
  "She's learning where she fits on the food chain, and I'm not sure you want her to figure that out.",
  "Mommy’s very angry.",
  "Welcome to Jurassic World.",
];

function Jordan() {
  return (
    <div style={{ height: "100%" }}>
      <GlobalStyles />
      <ThemeProvider theme={ash}>
        <Frame
          variant="outside"
          shadow
          style={{
            padding: "0.5rem",
            lineHeight: "1.5",
            width: "100%",
            margin: "0",
            padding: "0",
            height: "100%",
            boxSizing: "border-box",
            position: "relative",
          }}
        >
          <div>
            <marquee
              style={{
                background: "black",
                color: "red",
                height: "4rem",
                fontSize: "45px",
              }}
              behavior="scroll"
              direction="left"
            >
              {quotes.join(
                "\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"
              )}
            </marquee>
          </div>

          <div className={innerFrame}>
            {/*LEFT SIDE*/}
            <Frame
              variant="inside"
              style={{
                padding: "0.1rem 0.25rem",
                marginLeft: "10px",
                marginBottom: "10px",
                height: "90%",
                marginRight: "10px",
              }}
              className={leftSide}
            >
              <img src={map} alt="map" className={mapStyle} />
              <Frame
                style={{
                  height: "16%",
                  display: "grid",
                  placeItems:
                    "center" /* shorthand to center items both horizontally and vertically */,
                }}
              >
                <img
                  src={iconsleft}
                  alt="iconsleft"
                  className={iconsLeftStyle}
                  id={iconsLeft}
                />
              </Frame>
            </Frame>
            {useScreenSize}
            {/*RIGHT SIDE*/}
            <div className={rightSide}>
              <Default />
              <Frame variant="inside" style={{ width: "100%", height: "37%" }}>
                <img src={car} alt="car" className={carStyle} />
              </Frame>
              <JorTable />
            </div>
          </div>
        </Frame>
      </ThemeProvider>
    </div>
  );
}
export default Jordan;

import axios from "axios";
import { redirectToLogin } from "../utils/Auth";

function getCookie(searchTerm) {
  if (typeof window !== `undefined`) {
    let decodedCookie = decodeURIComponent(document.cookie);
    let cookies = decodedCookie.split(";");
    for (let cookie of cookies) {
      const [key, value] = cookie.split("=");
      if (key.includes(searchTerm)) return value;
    }
    return "";
  }
}

const idToken = getCookie("id_token");
const accessToken = getCookie("access_token");

const client = axios.create({
  baseURL: `${process.env.GATSBY_API_DOMAIN}/api/${process.env.GATSBY_API_VERSION}`,
  headers: {
    "x-api-key": process.env.GATSBY_API_KEY,
    "x-id-token": idToken,
    "x-access-token": accessToken,
  },
});

let abort = false;

async function request(method, url, data = {}, params = {}, headers = {}) {
  let result = {};

  await client
    .request({ method, url, data, params, headers })
    .then(({ status, data, headers }) => {
      result = { status, data, headers };
    })
    .catch((error) => {
      if (error.response) {
        result = {
          status: error.response.status,
          data: error.response.data,
          error,
        };
        if (error.response.status === 401) {
          if (abort) return;
          abort = true;
          redirectToLogin();
          return error;
        } else if (error.response.status === 403) {
          alert("You do not have permission for this action.");
          redirectToLogin();
          return error;
        }
      } else {
        const errorMessage = `Unknown HTTP error ${method} ${url}. Please try your request again. If error persists, please contact adavis@springoakscapital.com`;
        console.error(errorMessage, error.toJSON());
        alert(errorMessage);
        const newError = new Error(errorMessage);
        newError.httpError = error;

        throw newError;
      }
    });

  return result;
}

const get = (url, params, headers) => request("get", url, {}, params, headers);

const put = (url, data, params, headers) =>
  request("put", url, data, params, headers);

const post = (url, data, params, headers) =>
  request("post", url, data, params, headers);

export { client as default, get, post, put, getCookie };

import React, { useContext, useState } from "react";
import { Button, Frame } from "react95";
import { buttonRow } from "../../../../../styles/jordan.module.css";
import { JordanContext } from "./JordanContext";

export function Default() {
  const { setJordanDis } = useContext(JordanContext);

  const handleClick = () => {
    setJordanDis(false);
  };
  return (
    <div className={buttonRow}>
      <Frame variant="inside" style={{ width: "100%" }}>
        <Button size="lg" onClick={handleClick}>
          Juniper Home
        </Button>
        <Button size="lg">Vehicle</Button>
        <Button size="lg" primary>
          Automation
        </Button>
        <Button size="lg">Eco Mode</Button>
        <Button size="lg" primary>
          Tour
        </Button>
        <Button size="lg" primary>
          Power
        </Button>
        <Button size="lg" disabled>
          Operation
        </Button>
      </Frame>
    </div>
  );
}

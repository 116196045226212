import { Link } from "gatsby";
import React, { useContext } from "react";
import { header, thebox } from "../styles/header.module.css";
import Logo from "./Logo";
import Button from "@mui/material/Button";
import { Avatar, Popover, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { logout } from "../utils/Auth";
import UserContext from "./UserContext";

export default function Header() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { initials, user } = useContext(UserContext);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div className={header}>
      <Link to={"/"}>
        <Logo />
      </Link>
      {initials && (
        <div className={thebox}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Button variant="outlined" onClick={() => logout()}>
              Log out
            </Button>
            <Box mx={1}></Box>
            <Avatar sx={{ bgcolor: "#a08df3" }} onClick={handleClick}>
              {initials}
            </Avatar>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <Typography sx={{ p: 2 }}>User: {user}</Typography>
            </Popover>
          </Box>
        </div>
      )}
    </div>
  );
}
